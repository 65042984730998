import React from 'react'
import styled from '@emotion/styled';

import Container from "../components/Container";

import logo1 from "../images/stiftung.jpg";
import logo1_2x from "../images/stiftung@2x.jpg";
import logo3 from "../images/inlist.jpg";
import logo3_2x from "../images/inlist@2x.jpg";

const FooterWrapper = styled("footer")`
  overflow: hidden;
`;

const Grid = styled("div")`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 3rem;
  @media screen and (max-width: 1023px) {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
`;

const Col = styled("div")`
  padding-right: 2rem;
  @media screen and (max-width: 575px) {
    padding-right: 0;
  }

`;

const Authors = styled("div")`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  padding-top: 5rem;
  padding-bottom: 4rem;
  background-color: #201D1D;
  color: #ffffff;
  @media screen and (max-width: 1023px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
  @media screen and (max-width: 575px) {
    padding: var(--blockPadding);
    p {
      margin-bottom: 0;
    }
  }
  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: #201D1D;
    width: 66vw;
    @media screen and (max-width: 1023px) {
      left: -2rem;
      right: -2rem;
      top: 0;
      width: 100vw;
    }
  }
  h2 {
    margin-bottom: 1em;
  }
  a {
    color: #f9d83d;
  }
`;

const Partners = styled("div")`
  padding-bottom: 2rem;
  padding-left: 3.25rem;
  @media screen and (max-width: 1199px) {
    padding-left: 3rem;
  }
  @media screen and (max-width: 1023px) {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    order: -1;
    padding-right: 3rem;
  }
  @media screen and (max-width: 575px) {
    display: block;
    margin-top: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 3rem;
  }
  h2 {
    margin-top: 3rem;
    margin-bottom: 0;
    @media screen and (min-width: 576px) and (max-width: 1023px) {
      margin-top: 1em;
    }
  }
  a {
    display: block;
    margin-bottom: .5rem;
    @media screen and (max-width: 1023px) {
      margin-right: 2rem;
      margin-bottom: 0;
    }
    @media screen and (max-width: 575px) {
      margin-top: 1.5rem;
    }
    &:hover {
      img {
        transform: translateY(5px);
      }
    }
  }
  img {
    transition: transform .15s ease-in-out;
    @media screen and (max-width: 1023px) {
      max-width: 200px;
    }
  }
`;

const Logos = styled("div")`
  img {
    display: block;
    max-width: 100%;
    height: auto;
    @media screen and (max-width: 1023px) {
      max-width: 200px;
    }
  }
  a {
    margin-top: .5rem;
    margin-bottom: 1.5rem;
    @media screen and (max-width: 1023px) {
      margin-bottom: 0;
    }
  }

  p {
    margin-top: 1rem;
    margin-bottom: 0;
    font-size: .875rem;
  }
`;

export default () => {
  return (
    <>
      <FooterWrapper>
       <Container>
         <Grid>
          <Authors>
            <Col>
              <h2><a target="_blank" rel="noopener noreferrer" href="https://inlist.cz">Institut liberálních studií</a></h2>
              <p>Institut liberálních studií je nestátní, nezávislá, nezisková organizace – think-tank založený v roce 1989, jehož cílem je rozvíjet a aplikovat ideje a programy založené na principech klasického liberalismu. Naše aktivity jsou založené na hodnotách svobody jednotlivce, vlády s omezenými pravomocemi, volného trhu a míru.
              </p>
            </Col>
            <Col>
              <h2><a target="_blank" rel="noopener noreferrer" href="https://iness.sk/">INESS</a></h2>
              <p>Institut ekonomických a&nbsp;spoločenských analýz působí v&nbsp;oblasti analýz zaměřených na&nbsp;uplatňování principů a&nbsp;nástrojů volného trhu v&nbsp;podmínkách slovenské ekonomiky od&nbsp;ledna 2006. Nezávislý think tank INESS monitoruje činnost a&nbsp;financování veřejného sektoru, hodnotí efekty legislativních změn a&nbsp;komentuje aktuální ekonomická a&nbsp;spoločenská témata.</p>
            </Col>
          </Authors>
          <Partners id="partneri">
            <a target="_blank" rel="noopener noreferrer" href="http://inlist.cz"><img loading="lazy" alt="Logo - Institut liberálních studií" src={logo3} srcSet={`${logo3}, ${logo3_2x} 2x`} /></a>
            <Logos>
              <h2>Partneři</h2>
              <p>Tento projekt je podporován Friedrich&nbsp;Naumann&nbsp;Foundation&nbsp;for&nbsp;Freedom.</p>
              <a target="_blank" rel="noopener noreferrer" href="http://www.freiheit.org/Aktuell/11c/index.html"><img width={300} height={84} loading="lazy" alt="Logo - freiheit" src={logo1} srcSet={`${logo1}, ${logo1_2x} 2x`} /></a>
            </Logos>
          </Partners>
         </Grid>
       </Container>
      </FooterWrapper>
    </>
  );
};
