import React from "react";
import styled from '@emotion/styled'

import Container from "./Container"

import methodicImage from "../images/methodic.svg"

const Grid = styled("div")`
  display: grid;
  gap: 2rem 4rem;
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
  @media screen and (max-width: 850px) {
    grid-template-columns: 1fr;
  }
`;

const Block = styled("div")`
  margin-bottom: var(--blockVerticalSpacing);
  padding: var(--blockPadding);
  background-color: var(--white);
  box-shadow: var(--boxShadow);
`;

const Text = styled("div")`
  .text-danger {
    color: #A7171A;
  }
`;

const Image = styled("img")`
  display: block;
  position: relative;
  @media screen and (max-width: 850px) {
    display: none;
  }
`;


export default () => {
  return (
    <Container>
      <Block id="metodologie">
        <Grid>
          <Text>
            <h2>Výpočet</h2>
            <p>Cena zaměstnance představuje odhad celkového nákladu zaměstnavatele na&nbsp;zaměstnání daného zaměstnance. Ty jsou totiž reálně výrazně vyšší než hrubá i&nbsp;než od&nbsp;roku 2021 zrušená superhrubá mzda. Výpočet ceny zaměstnance tak&nbsp;zahrnuje kromě daní z&nbsp;příjmů, solidární daně a&nbsp;odvodů také bonusy, dny volna navíc, příplatky za&nbsp;přesčasy, práci o&nbsp;víkendu či&nbsp;o&nbsp;svátky, stravenky či&nbsp;stravenkový paušál, nebo koncesionářské poplatky, pokud zaměstnanci při&nbsp;práci poslouchají rádio.</p>
            <p>Tento údaj je&nbsp;relevantní proto, že&nbsp;zaměstnanci svou prací musejí pokrýt nejen svou mzdu, ale všechny spojené náklady zaměstnavatele, aby&nbsp;se&nbsp;zaměstnavateli vyplatilo jejich pracovní místo udržovat. </p>
            <p>Vložte tyto údaje do&nbsp;naší kalkulačky a&nbsp;zjistěte, kolik stojí konkrétní pracovní místo.</p>
            <p>Samotná metoda výpočtu je&nbsp;po&nbsp;přizpůsobení k&nbsp;českému daňovému systému a&nbsp;zákoníku práce převzatá od&nbsp;partnerů ze&nbsp;slovenského think-tanku <a target="_blank" rel="noopener noreferrer" href="https://iness.sk/">INESS</a>, což&nbsp;umožňuje pro&nbsp;odpovídající pozice dobré mezinárodní srovnání.</p>
            <p className="text-danger"><strong>Aktualizováno v říjnu 2022.</strong></p>
          </Text>
          <Image src={methodicImage} />
        </Grid>
      </Block>
    </Container>
  );
};
