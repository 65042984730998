import React from "react";
import styled from '@emotion/styled'

import Container from "./Container"

import professionDoctor from "../images/profession-doctor.svg"
import professionNurse from "../images/profession-nurse.svg"
import professionCashier from "../images/profession-cashier.svg"


const Block = styled.div`
  margin-bottom: var(--blockVerticalSpacing);
  padding: var(--blockPadding);
  background-color: var(--white);
  box-shadow: var(--boxShadow);
`;

const Table = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 1fr 1fr 1fr;
  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    div:nth-of-type(1) {
      order: 1;
    }
    div:nth-of-type(2) {
      order: 2;
    }
    div:nth-of-type(6) {
      order: 3;
    }
    div:nth-of-type(10) {
      order: 4;
    }
    div:nth-of-type(14) {
      order: 5;
    }
    div:nth-of-type(18) {
      order: 6;
      margin-bottom: 1rem;
    }
    div:nth-of-type(3) {
      order: 7;
    }
    div:nth-of-type(7) {
      order: 8;
    }
    div:nth-of-type(11) {
      order: 9;
    }
    div:nth-of-type(15) {
      order: 10;
    }
    div:nth-of-type(19) {
      order: 11;
      margin-bottom: 1rem;
    }
    div:nth-of-type(4) {
      order: 12;
    }
    div:nth-of-type(8) {
      order: 13;
    }
    div:nth-of-type(12) {
      order: 14;
    }
    div:nth-of-type(16) {
      order: 15;
    }
    div:nth-of-type(20) {
      order: 16;
    }
  }
`

const TableHeadCell = styled.div`
  h2 {
    margin-top: .25em;
    @media screen and (max-width: 800px) {
      text-align: center;
    }
  }

  @media screen and (max-width: 800px) {
    img {
      display: block;
      width: 250px;
      margin-bottom: 1rem;
      margin-left: auto;
      margin-right: auto;
    }
  }
`

const TableBodyCell = styled.div`
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  span {
    display: none;
  }
  @media screen and (max-width: 800px) {
    span {
      display: block;
    }
  }
`

const TableBodyCellLabel = styled.div`
  text-align: left;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  @media screen and (max-width: 800px) {
    display: none;
  }
`

const TableText = styled.div`
  padding: .2rem 0;
`

export default () => {
  return (
    <Container>
      <Block id="priklady">
        <Table>
          <TableHeadCell>
            <h2>Vybrané příklady</h2>
          </TableHeadCell>
          <TableHeadCell>
            <img src={professionNurse} />
          </TableHeadCell>
          <TableHeadCell>
            <img src={professionDoctor} />
          </TableHeadCell>
          <TableHeadCell>
            <img src={professionCashier} />
          </TableHeadCell>
          <TableBodyCellLabel>
            <TableText>
              Zaměstnání:
            </TableText>
          </TableBodyCellLabel>
          <TableBodyCell>
            <TableText>
              <strong>Zdravotní sestra</strong>
            </TableText>
          </TableBodyCell>
          <TableBodyCell>
            <TableText>
              <strong>Lékař/ka</strong>
            </TableText>
          </TableBodyCell>
          <TableBodyCell>
            <TableText>
              <strong>Prodavač/ka v Lidlu</strong>
            </TableText>
          </TableBodyCell>
          <TableBodyCellLabel>
            <TableText>
              Průměrný smluvní plat:
            </TableText>
          </TableBodyCellLabel>
          <TableBodyCell>
            <TableText>
              <span>Průměrný smluvní plat:</span>
              <strong>34 003 Kč</strong>
            </TableText>
          </TableBodyCell>
          <TableBodyCell>
            <TableText>
              <span>Průměrný smluvní plat:</span>
              <strong>67 215 Kč</strong>
            </TableText>
          </TableBodyCell>
          <TableBodyCell>
            <TableText>
              <span>Průměrný smluvní plat:</span>
              <strong>29 700 Kč</strong>
            </TableText>
          </TableBodyCell>
          <TableBodyCellLabel>
            <TableText>
              Cena zaměstnance:
            </TableText>
          </TableBodyCellLabel>
          <TableBodyCell>
            <TableText>
              <span>Cena zaměstnance:</span>
              <strong>62 170 Kč</strong>
            </TableText>
          </TableBodyCell>
          <TableBodyCell>
            <TableText>
              <span>Cena zaměstnance:</span>
              <strong>116 176 Kč</strong>
            </TableText>
          </TableBodyCell>
          <TableBodyCell>
            <TableText>
              <span>Cena zaměstnance:</span>
              <strong>50 603 Kč</strong>
            </TableText>
          </TableBodyCell>
          <TableBodyCellLabel>
            <TableText>
              Cena zaměstnance nad rámec smluvního platu:
            </TableText>
          </TableBodyCellLabel>
          <TableBodyCell>
            <TableText>
              <span>Cena zaměstnance nad rámec smluvního platu:</span>
              <strong>83 %</strong>
            </TableText>
          </TableBodyCell>
          <TableBodyCell>
            <TableText>
              <span>Cena zaměstnance nad rámec smluvního platu:</span>
              <strong>73 %</strong>
            </TableText>
          </TableBodyCell>
          <TableBodyCell>
            <TableText>
              <span>Cena zaměstnance nad rámec smluvního platu:</span>
              <strong>70 %</strong>
            </TableText>
          </TableBodyCell>
        </Table>
      </Block>
    </Container>
  );
};
