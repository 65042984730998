import React from "react"
import { Link } from "gatsby"
import styled from '@emotion/styled'

import Container from "./Container"
import logo from "../images/logo.svg"
import background from "../images/header.jpg"
import backgroundMD from "../images/header-md.jpg"
import backgroundXS from "../images/header-xs.jpg"

if (typeof window !== "undefined") {
  /* tslint:disable */
  require("smooth-scroll")('a[href*="#"]', {
    speed: "500",
    easing: "easeInOutQuad",
    updateURL: true
  });
  /* tslint:enable */
}

const HeaderBackground = styled("div")`
  position: relative;
  padding-top: var(--blockVerticalSpacing);
  padding-bottom: var(--blockVerticalSpacing);
  &::before {
    content: "";
    z-index: -1;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 37.5rem;
    background-image: url(${background});
    background-size: cover;
    background-position: center center;
    @media screen and (max-width: 900px) {
      background-image: url(${backgroundMD});
      height: 20rem;
    }
    @media screen and (max-width: 575px) {
      background-image: url(${backgroundXS});
      height: 100%;
      bottom: 0;
    }
  }
`

const Navbar = styled("header")`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
`

const Nav = styled("nav")`
  display: flex;
  flex-wrap: nowrap;
  background-color: var(--white);
  min-height: 41px;
`

const Menu = styled("ul")`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0 1rem;
  min-height: 42px;
  @media screen and (max-width: 1019px) {
    padding: 0 .7rem;
  }
`;

const MenuItem = styled("li")`
  display: flex;
  min-height: 42px;
  a {
    display: inline-flex;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    font-weight: 700;
    text-decoration: none;
    color: var(--darkGray);
    transition: color .15s ease-in-out, background-color .15s ease-in-out;
    @media (hover: hover) {
      &:hover {
          color: var(--yellow);
          background-color: var(--darkGray);
          text-decoration: none;
        }
      }
    @media screen and (max-width: 575px) {
      padding-left: .7rem;
      padding-right: .7rem;
    }
  }
`;

const Logo = styled(Link)`
  color: var(--white);
  padding: .5rem .75rem;
  background-color: var(--darkGray);
  text-decoration: none;
  @media screen and (max-width: 1019px) {
    padding-left: 1.4rem;
    padding-right: 1.4rem;
  }
  h1 {
    margin: 0;
    font-size: 2rem;
    font-weight: 400;
    text-transform: uppercase;
    white-space: nowrap;
    @media screen and (max-width: 575px) {
      font-size: 1.5rem;
    }
  }
`

export default () => {

  return (
    <>
      <HeaderBackground>
        <Container>
          <Navbar role="navigation" aria-label="main-navigation">
            <Logo to="/" title="Logo">
              <h1>Cena zaměstnance</h1>
            </Logo>
            <Nav>
              <Menu>
                <MenuItem>
                  <a href="#kalkulacka">Kalkulačka</a>
                </MenuItem>
                 <MenuItem>
                  <a href="#priklady">Příklady</a>
                </MenuItem>
                <MenuItem>
                  <a href="#metodologie">Metodologie</a>
                </MenuItem>
                <MenuItem>
                  <a href="#podporte-nas">Podpořte nás</a>
                </MenuItem>
                <MenuItem>
                  <a href="#partneri">Partneři</a>
                </MenuItem>
                <MenuItem>
                  <a href="#kontakt">Kontakt</a>
                </MenuItem>
              </Menu>
            </Nav>
          </Navbar>
        </Container>
      </HeaderBackground>
    </>
  );
};
