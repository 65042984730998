import React from "react";
import styled from '@emotion/styled'

import Container from "./Container"

import bankovniPrevod from "../images/bankovni-prevod.jpg"
import btcAdress from "../images/btcadresalibinst.jpg"

const Grid = styled.div`
  display: grid;
  gap: 2rem 3rem;
  grid-template-columns: repeat(3, 1fr);
  @media screen and (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`;

const Block = styled.div`
  margin-bottom: var(--blockVerticalSpacing);
  padding: var(--blockPadding);
  background-color: var(--white);
  box-shadow: var(--boxShadow);
`;

const PaymentItem = styled.div`
  a {
    word-break: break-all;
  }
`;


const Image = styled.img`
  position: relative;
  display: block;
  width: 250px;
  max-width: 100%;
  margin-bottom: 1rem;
  @media screen and (max-width: 575px) {
    width: 150px;
  }
`;

const List = styled.ul`
  padding-left: 20px;
`


export default () => {
  return (
    <Container>
      <Block id="podporte-nas">
        <h2>Podpořte nás</h2>
        <Grid>
          <PaymentItem>
            <h3>Bankovním převodem</h3>
            <Image src={bankovniPrevod} />
            <List>
              <li>číslo účtu: 2101849037 / 2010</li>
              <li>banka: Fio</li>
              <li>IBAN: CZ87 2010 0000 0021 0184 9037</li>
              <li>BIC: FIOBCZPP</li>
            </List>
          </PaymentItem>
          <PaymentItem>
            <h3>Bitcoinem</h3>
            <Image src={btcAdress} />
            <p><a target="_blank" rel="noopener noreferrer" href="https://www.blockchain.com/btc/address/3DyLVm2w78DPcZRe1cjy4ANBMmLFLvJUUD">3DyLVm2w78DPcZRe1cjy4ANBMmLFLvJUUD</a></p>
          </PaymentItem>
          <PaymentItem>
            <h3>PayPalem</h3>
            <p><a target="_blank" rel="noopener noreferrer" href="https://www.paypal.com/paypalme/libinst">PayPal.me/libinst</a></p>
          </PaymentItem>
        </Grid>
      </Block>
    </Container>
  );
};
