import React from "react";
import styled from '@emotion/styled'

import Container from "./Container"

const Block = styled.div`
  margin-bottom: var(--blockVerticalSpacing);
  padding: var(--blockPadding);
  background-color: var(--white);
  box-shadow: var(--boxShadow);
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem 3rem;
  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`

const Contact = styled.div`
  p {
    margin-bottom: 0;
  }
`

export default () => {
  return (
    <Container>
      <Block id="kontakt">
        <h2>Kontakt</h2>
        <Grid>
          <Contact>
            <h3>Gabriela Řezníčková</h3>
            <p><em>Projektová manažerka Institutu liberálních studií</em></p>
            <p><a href="mailto:gabriela.reznickova@inlist.cz">gabriela.reznickova@inlist.cz</a></p>
          </Contact>
          <Contact>
            <h3>Martin Pánek</h3>
            <p><em>Ředitel Institutu liberálních studií</em></p>
            <p><a href="mailto:martin.panek@inlist.cz">martin.panek@inlist.cz</a></p>
            <p><a href="tel:777157142">777 157 142</a></p>
          </Contact>
          <Contact>
            <h3>Jan Mošovský</h3>
            <p><em>Vedoucí projektu Cena zaměstnance</em></p>
            <p><a href="mailto:jan.mosovsky@inlist.cz">jan.mosovsky@inlist.cz</a></p>
            <p><a href="tel:721333160">721 333 160</a></p>
          </Contact>
        </Grid>
      </Block>
    </Container>
  );
};
