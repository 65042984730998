import React from "react";
import styled from "@emotion/styled"

const ContainerWrapper = styled("div")`
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--gutterWidth);
  padding-right: var(--gutterWidth);
`

const Container = ({ children }) => {
  return (
    <ContainerWrapper>
      {children}
    </ContainerWrapper>
  );
}

export default Container;
