import * as React from "react"
import { Helmet } from 'react-helmet'

import { GlobalStyles } from "../components/Global"
import Calculator from "../components/Calculator"
import Header from "../components/Header"
import Examples from "../components/Examples"
import Methodic from "../components/Methodic"
import SupportUs from "../components/SupportUs"
import Contact from "../components/Contact"
import Footer from "../components/Footer"

const metaTitle = "Cena zaměstnance | Institut liberálních studií";
const metaDescription = "Cena zaměstnance představuje odhad celkového nákladu zaměstnavatele na zaměstnání daného zaměstnance. Ty jsou totiž reálně výrazně vyšší než hrubá i než od roku 2021 zrušená superhrubá mzda.";

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta
          property="og:image"
          content="https://www.cenazamestnance.cz/og-facebook.png"
        />
        <meta property="og:url" content="/" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:image"
          content="https://www.cenazamestnance.cz/og-twitter.png"
        />
        <meta name="twitter:image:alt" content={metaDescription} />
        <meta name="twitter:site" content="@libinstcz" />
        <meta name="twitter:creator" content="@libinstcz" />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />

        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#F9D83D" />
        <meta name="msapplication-TileColor" content="#201D1D" />
        <meta name="theme-color" content="#201D1D" />
      </Helmet>
      <GlobalStyles />
      <Header />
      <Calculator />
      <Examples />
      <Methodic />
      <SupportUs />
      <Contact />
      <Footer />
    </>
  )
}

export default IndexPage
